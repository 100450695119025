import Image from "next/image";
import Link from "next/link";

const BestDeals = () => {
  return (
    // <section
    // 	id='best-deals'
    // 	className='newsletter-gradient overflow-clip relative sm:flex items-center justify-start lg:gap-[70px] lg:px-[45px] lg:py-[41px]  my-[24px] max-w-7xl mx-auto rounded-[20px]'>
    // 	<div className='h-[1000px] w-[1000px] best-deals-yellow-gradient rounded-full absolute -right-[300px]  z-10'></div>
    // 	<div className='best-deals-yellow-2-gradient h-[800px] w-[800px] rounded-full absolute   -right-[300px]  z-10'></div>
    // 	<Image
    // 		src='/best-deals/best-deals-person.webp'
    // 		height={436}
    // 		width={436}
    // 		alt='Person holding two cars'
    // 		className='hidden lg:absolute lg:grid left-0 right-0 mx-auto z-30'
    // 	/>
    // 	<div className='flex justify-between items-center w-full relative z-50'>
    // 		<div>
    // 			<h2 className='font-normal text-[34px] text-[#EEF2FF] -mb-3'>
    // 				Compare Rates for the
    // 			</h2>
    // 			<h3 className='font-extrabold text-[78px] italic text-white best-deals-text-shadow'>
    // 				Best deals!
    // 			</h3>
    // 		</div>
    // 		<div className='flex flex-col items-end'>
    // 			<div className='flex items-center justify-end gap-[25px] mb-[24px]'>
    // 				<h2 className='font-semibold text-3xl text-white'>
    // 					Get Up to 80% off
    // 				</h2>
    // 				<Image
    // 					src='/best-deals/decoration.svg'
    // 					height={17}
    // 					width={28}
    // 					alt='Decoration icon'
    // 				/>
    // 			</div>
    // 			<div className='flex items-center justify-end gap-[25px] mb-[47px]'>
    // 				<h3 className='font-semibold text-3xl text-white'>
    // 					Free cancellation
    // 				</h3>
    // 				<Image
    // 					src='/best-deals/decoration.svg'
    // 					height={17}
    // 					width={28}
    // 					alt='Decoration icon'
    // 				/>
    // 			</div>
    // 			<button className='bg-white text-[#0057FF] px-14 py-5 rounded-[14px] font-semibold text-xl'>
    // 				Book Now
    // 			</button>
    // 		</div>
    // 	</div>
    // </section>
    <section
      id="best-deals"
      className="px-[13px] lg:px-0 max-w-7xl mx-auto mt-[22px] lg:mt-[40px]"
    >
      <Link href="https://www.kayak.com/cars" target="_blank">
        <Image
          src="/best-deals/best-deals.webp"
          height={302}
          width={1440}
          alt="Book deals banner"
        />
      </Link>
    </section>
  );
};

export default BestDeals;
